<template>
    <div class="media-center-page page-p-t">
        
        <div class="tab-box margin-both">
            <p @click="changeTab('1')" :class="this.query.newsType == '1' ? 'active-state': ''">公司动态</p>
            <p @click="changeTab('2')" :class="this.query.newsType == '2' ? 'active-state': ''">行业动态</p>
        </div>
        <ul class="item-list">
            <li class="item-box" v-for="t in tableData" :key="t.newsId">
                <div class="img-box"><img :src="t.newsUrl"/></div>
                <p class="title"> {{ t.newsTitle }}</p>
                <img @click="jumpTo(t)" class="next-img" src="@/assets/img/aboutAsyou/next.png"/>
            </li>
        </ul>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="query.pageNum"
                :page-size="query.pageSize"
                :page-sizes="[10, 20, 50, 100]"
                @size-change="handleSizeChange"
                layout=" prev, pager, next, jumper, total"
                :total="response.total"
                >
            </el-pagination>
        </div>
        <ContactInformation style="margin-top: 1.8rem;"></ContactInformation>

    </div>
</template>
<script>
import { newsPage } from '@/api/index'
export default {
    name: 'MediaCenter',
    data() {
        return {
            query: {
                newsType: '1',
                pageNum: 1,
                pageSize: 6
            },
            response: {},
            tableData: []
        }
    },
    created(){
        this.queryList();
    },
    methods: {
        jumpTo(t) {
            this.$router.push({
                name: 'mediaDetail',
                query: {
                    newsId: t.newsId
                }
            })
        },
        handleCurrentChange(data) {
            this.$set(this.query, "pageNum", data);
            this.queryList();
        },
        handleSizeChange(val) {
            this.$set(this.query, "pageSize", val);
            this.$set(this.query, "pageNum", 1);
            this.queryList();
        },
        changeTab(type) {
            this.query.newsType = type;
            this.query.pageNo = 1;
            this.queryList();
        },
        queryList() {
            newsPage(this.query).then(res => {
                this.response = res.data;
                this.tableData = this.response.rows;
            })
        }
    }
}
</script>
<style lang="scss">
.el-input__inner {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 1px solid rgba($color: #282C4D, $alpha: 1) !important;
    border-radius: 5px !important;
    }    
.el-input__inner:focus {
    border: 1px solid rgba($color: #a3a3ae, $alpha: 0.80) !important;
}
.page-box {
    .el-pagination .btn-next, .el-pagination .btn-prev {
        background: center center no-repeat rgba(0, 0, 0, 0) !important;
    }
    .el-pagination {
        color: white !important;
    }
    .el-pager li {
        background: rgba($color:rgba(0, 0, 0, 0), $alpha: 1) !important;
    }
    .el-pagination__total {
        margin-left: 10px;
    }
}
    
</style>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";
    .margin-both {
        margin-left: $whole-lr;
        margin-right: $whole-lr;
    }
    .media-center-page {
        overflow-y: scroll;
        color: white;
        background: url(../../assets/img/aboutAsyou/color-bg.png) no-repeat;
        background-size:contain;

        .tab-box {
            display: flex;
            align-items: center;
            margin-top: 0.8rem;
            p {
                position: relative;
                cursor: pointer;
                margin-right: 0.64rem;
                font-size: 0.32rem;
                color: rgba($color: #fff, $alpha: 0.6);
                overflow: hidden;
                padding-bottom: 0.08rem;
            }
            p:hover {
                color: rgba($color: #fff, $alpha: 0.8);

            }
            p::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -0.05rem;
                height: 0.05rem;
                border: 0.05rem solid;
                border-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, RGBA(84, 212, 247, 0.618) 50%, rgba(255, 255, 255, 0) 100%) 0.5 0.5;
                transition: transform .3s;
                transform: translate(-100%, 0);
            }
            p.active-state::after {
                transform: translate(0, 0);
            }
            p.active-state {
                font-size: 0.38rem;
                color: white;
            }
            
        }
        .item-list {
            display:flex;
            
            margin-left: $whole-lr;
            margin-right: $whole-lr;
            flex-wrap: wrap;
            justify-content: space-between;
            .item-box {
                position: relative;
                margin-top: 0.64rem;
                width: 48%;
                height: 3.32rem;
                background: url(../../assets/img/aboutAsyou/item-bg.png) no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                padding: 0.16rem;
                .img-box {
                    height: 2.88rem;
                    width: 100%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .title {
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
                    margin-left: 0.08rem;
                    padding-top: 0.22rem;
                    height: 0.48rem;
                    @include font-section;
                    letter-spacing: 2px;
                }

                .next-img {
                    cursor: pointer;
                    position: absolute;
                    right: 0.16rem;
                    bottom: 0.16rem;
                    width: 0.73rem;
                    height: 0.73rem;
                }
            }
        }
        .page-box {
            margin-top: 1.5rem;
            padding-bottom: 1.5rem;
            text-align: center;
        }

    }
</style>